/**
 * @file Anything that can run on every page.
 */

/**
	* Global functions.
	*
	* Runs component js and any global tasks.
	*/
const vGlobal = () => {
	if (document.querySelector('.v-alert[data-id]')) import('../components/alert/alert').then((m) => m.default());
	// import('@elbwalker/walker.js').then((m) => m.default());
	import('../components/analytics/analytics').then((m) => m.default());
	import('../components/header/main-nav').then((m) => m.default());
	import('../components/header/mobile-nav').then((m) => m.default());
	import('../components/header/search-dropdown').then((m) => m.default());
	import('../components/header/login-dropdown').then((m) => m.default());
	import('../components/header/login-form').then((m) => m.default());
	import('../components/login-button/login-button').then((m) => m.default());
	import('./partials/anchor-link').then((m) => m.default());
	import('../components/contact-cta/contact-cta').then((m) => m.default());
	import('../components/speedbump-dialog/speedbump-dialog').then((m) => m.default());
	if (document.querySelector('.v-icons')) import('../components/icons/icons').then((m) => m.default());
	if (document.querySelector('.v-newsletter-banner')) import('../components/newsletter-banner/newsletter-banner').then((m) => m.default());
	if (document.querySelector('#disclosure')) import('../components/disclosure/disclosure').then((m) => m.default());
	if (document.querySelector('.v-cisco-chat')) import('../components/cisco-chat/cisco-chat').then((m) => m.default());
	if (document.querySelector('.v-accordions') && document.querySelectorAll('.v-accordions .v-accordions__trigger').length) import('../components/accordions/accordions').then((m) => m.default());

	const selects = document.querySelectorAll('.v-form select, .v-select');
	[...selects].forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('v-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		wrapper.classList.add('v-form__select');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
	});

	const checkboxes = document.querySelectorAll('[type="checkbox"]');
	[...checkboxes].forEach((checkbox) => {
		const checkboxParent = checkbox.parentNode;
		const checkboxNext   = checkbox.nextElementSibling;
		if (checkboxNext && checkboxNext.classList.contains('v-form__checkbox')) {
			return;
		}

		const customBox = document.createElement('span');
		customBox.classList.add('v-form__checkbox');
		checkboxParent.insertBefore(customBox, checkbox.nextSibling);
		checkbox.classList.add('hidden');
	});

	const radios = document.querySelectorAll('[type="radio"]');
	[...radios].forEach((radio) => {
		const radioParent = radio.parentNode;
		const radioNext   = radio.nextElementSibling;
		if (radioNext && radioNext.classList.contains('v-form__radio')) {
			return;
		}

		const customBox = document.createElement('span');
		customBox.classList.add('v-form__radio');
		radioParent.insertBefore(customBox, radio.nextSibling);
		radio.classList.add('hidden');
	});

	const floatingLabels = document.querySelectorAll('.v-form__label-text--float');
	if (floatingLabels.length) {
		const floatingInputs = [...floatingLabels].map((fL) => {
			const fLWrap = fL.parentNode;
			return fLWrap.querySelector('input, textarea, select, .facetwp-facet');
		});

		floatingInputs.forEach((fI, i) => {
			// const fIWrap = fI.parentNode;
			if (fI && !fI.parentNode.classList.contains('facetwp-facet')) {
				fI.addEventListener('focusin', () => {
					floatingLabels[i].classList.add('v-form__label-text--float-up');
					const fIPlaceholder = fI.getAttribute('placeholder');
					if (fIPlaceholder) {
						fI.dataset.ph = fIPlaceholder;
						fI.removeAttribute('placeholder');
					}
				});

				fI.addEventListener('focusout', () => {
					const isWrap = fI.classList.contains('facetwp-facet');
					if (isWrap) {
						const input = fI.querySelector('input, textarea, select');
						if (!input.value) {
							floatingLabels[i].classList.remove('v-form__label-text--float-up');
							if (fI.dataset.ph) {
								fI.setAttribute('placeholder', fI.dataset.ph);
							}
						}
					} else if (!fI.value) {
						floatingLabels[i].classList.remove('v-form__label-text--float-up');
						if (fI.dataset.ph) {
							fI.setAttribute('placeholder', fI.dataset.ph);
						}
					}
				});
			}

			// one off show hide placeholder on focus
			// in/out for site search field
			if (fI.parentNode.classList.contains('v-search-dropdown__search-label')) {
				fI.addEventListener('focusin', () => {
					floatingLabels[i].classList.add('v-form__label-text--float-up');
				});
				fI.addEventListener('focusout', () => {
					floatingLabels[i].classList.remove('v-form__label-text--float-up');
				});
			}
		});
	}

	// 1. Get form el with role=status
	// 2. Update textContent of role=status el to 'Loading'
	// 3. Hope that cf7 dispatched events will clear out/override el content w/ plugin output
	// 4. Not working as I'd hope

	// const submit = document.querySelector('.wpcf7-submit');
	// submit.addEventListener('click', () => {
	// 	document.querySelector('.wpcf7 .screen-reader-response [role="status"]').textContent = 'Loading';
	// });

	// document.addEventListener('wpcf7invalid', (e) => { e.target.closest('.wpcf7').querySelector('.screen-reader-response [role="status"]').textContent = ''; });
	// document.addEventListener('wpcf7spam', (e) => { e.target.closest('.wpcf7').querySelector('.screen-reader-response [role="status"]').textContent = ''; });
	// document.addEventListener('wpcf7mailsent', (e) => { e.target.closest('.wpcf7').querySelector('.screen-reader-response [role="status"]').textContent = ''; });
	// document.addEventListener('wpcf7mailfailed', (e) => { e.target.closest('.wpcf7').querySelector('.screen-reader-response [role="status"]').textContent = ''; });

	document.addEventListener('wpcf7submit', (e) => {
		// accessibility: insert role=status to loading icon
		// e.target.closest('.wpcf7').querySelector('.screen-reader-response [role="status"]').textContent = 'Loading';

		setTimeout(() => {
			if (floatingLabels.length) {
				const floatingInputs = [...floatingLabels].map((fL) => {
					const fLWrap = fL.parentNode;
					return fLWrap.querySelector('input, textarea, select');
				});

				floatingInputs.forEach((fI, i) => {
					if (!fI.value) {
						floatingLabels[i].classList.remove('v-form__label-text--float-up');
						if (fI.dataset.ph) {
							fI.setAttribute('placeholder', fI.dataset.ph);
						}
					}
				});
			}
		}, 10);
	});

	//   const links    = ;
	const external = Array.from(document.querySelectorAll('a[target="_blank"], a.v-speedbump'))
		.filter((link) => {
			if (!link.ariaLabel) {
				return true;
			}

			return !(
				link.ariaLabel.indexOf('new tab') !== -1
				|| link.ariaLabel.indexOf('new window') !== -1
			);
		});

	external.forEach((e) => { e.ariaLabel = `${e.innerText} - opens in a new window`; });

	/* iFrame Accessibility fix */
	const quoteScript = document.querySelector('#dr-quoter-widget-764-script');

	if (quoteScript) {
		const wrapper = quoteScript.parentElement;

		/**
		 * Adds title to iframe, removes obsolete attribute.
		 *
		 * @param {Array} ml - Array of MutationRecord objects.
		 * @param {object} o  - MutationObserver object.
		 */
		const addIframeTitle = (ml, o) => {
			[...ml].forEach((m) => {
				if (m.type === 'childList') {
					const { addedNodes } = m;

					[...addedNodes].forEach((n) => {
						const { nodeType } = n;

						if (nodeType !== 1) {
							return;
						}

						const frame     = n.querySelector('iframe');
						const dateGroup = n.querySelector('.date-group');
						const submitBtn = n.querySelector('.widget-btn[ng-click*="getQuotes"]');

						if (frame && !frame.classList.contains('v-quote-initialized')) {
							frame.setAttribute('title', document.querySelector('h1').textContent);
							frame.removeAttribute('frameborder');
							frame.classList.add('v-quote-initialized');
						}

						if (dateGroup && !dateGroup.classList.contains('v-quote-initialized')) {
							const month = dateGroup.querySelector('#birth_month');
							const day   = dateGroup.querySelector('#birth_day');
							const year  = dateGroup.querySelector('#birth_year');

							month.setAttribute('autocomplete', 'bday-month');
							day.setAttribute('autocomplete', 'bday-day');
							year.setAttribute('autocomplete', 'bday-year');
						}

						// BTN is last to load, so we can remove the observer here.
						if (submitBtn && !submitBtn.classList.contains('v-quote-initialized')) {
							submitBtn.setAttribute('aria-label', 'Get Quotes');
							submitBtn.classList.add('v-quote-initialized');

							document.dispatchEvent(new CustomEvent('vIframeBtnInit', { detail: { btn: submitBtn } }));
							o.disconnect();
						}
					});
				}
			});
		};

		if (!wrapper.classList.contains('v-quote-initialized')) {
			const options  = { childList: true, subtree: true };
			const observer = new MutationObserver(addIframeTitle);

			observer.observe(wrapper, options);
			wrapper.classList.add('v-quote-initialized');
		}
	}

	/* Empty ul in wpcf7 component fix */
	const wpcf7Elems = document.querySelectorAll('.wpcf7');

	if (wpcf7Elems) {
		/**
		 * Removes the aria-hidden attribute from the wpcf7 screenreader list.
		 *
		 * @param {Array} ml - Array of MutationRecord objects.
		 * @param {object} o - MutationObserver object.
		 */
		const removeHidden    = (ml, o) => {
			[...ml].forEach((m) => {
				if (m.type === 'childList') {
					const list = m.target;

					if (list.hasAttribute('aria-hidden') && (m.removedNodes || m.addedNodes)) {
						list.removeAttribute('aria-hidden');
						o.disconnect();
					}
				}
			});
		};

		[...wpcf7Elems].forEach((contact) => {
			const emptyUl      = contact.querySelector('.screen-reader-response ul');

			if (!emptyUl
				|| emptyUl.hasChildNodes()
			) {
				return;
			}

			if (!emptyUl.hasAttribute('aria-hidden')) {
				const observerOptions = { childList: true };
				const observer        = new MutationObserver(removeHidden);

				// screen readers ignore if empty
				emptyUl.setAttribute('aria-hidden', 'true');

				observer.observe(emptyUl, observerOptions);
			}
		});
	}

	/* Missing alt tag on Roku tracking pixel fix */
	setTimeout(() => {
		const imgs = Array.from(document.querySelectorAll('img')).filter((i) => !i.hasAttribute('alt'));

		if (!imgs) {
			return;
		}

		[...imgs].forEach((i) => {
			i.setAttribute('alt', '');
		});
	}, 1000);
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}

document.addEventListener('DOMContentLoaded', function() {
    document.addEventListener('wpcf7invalid', function(event) {
        //console.log("wpcf7invalid event triggered");
        var invalidFields = event.detail.apiResponse.invalid_fields;
        //console.log("invalidFields: ", invalidFields);
        if (invalidFields && invalidFields.length > 0) {
			setTimeout(() => {
				// Get the form that triggered the event
				const form = event.target;
				// Find the first invalid field within that form
				const firstInvalidField = form.querySelector('.wpcf7-not-valid');
				//console.log("firstInvalidField: ", firstInvalidField);
				if (firstInvalidField){
					firstInvalidField.focus();
				}
			}, 500);
        }
    }, false);
});